import React from "react";
import Solana from "../../assets/img/powered-by-solana.png";
import LeftLeaf from "../../assets/img/leftLeaf.png";
import RightLeaf from "../../assets/img/right-leaf.png";
import {
  CurcelIcon,
  DownIcon,
  LineIcon,
  StarsIcon,
  XIcon,
} from "../common/Icons";
import Shadow from "../../assets/img/shadow.png";
import Icon1 from "../../assets/img/icon-1.png";
import Icon2 from "../../assets/img/icon-2.png";
import Icon3 from "../../assets/img/icon-3.png";
import Icon4 from "../../assets/img/icon-4.png";
import Icon5 from "../../assets/img/icon-5.png";
function Partners() {
  return (
    <>
    
    </>
  );
}

export default Partners;
