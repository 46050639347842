import React from "react";
import telegram from "../../assets/img/telegram.png";
import twitter from "../../assets/img/twitter.png";

const Sidebar = ({ isSidebarOpen, setSidebarOpen }) => {
  return (
    <>
      <aside
        className={`w-full bg-[#1A365E] h-screen fixed top-0 transition-all duration-200 ease-in z-20 lg:hidden ${
          isSidebarOpen ? "left-0" : "-left-full"
        }`}
      >
        <span
          className="absolute right-3 top-8 cursor-pointer"
          onClick={() => setSidebarOpen(false)}
        >
          <svg
            width="36"
            height="36"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.25 4.75L4.75 11.25M4.75 4.75L11.25 11.25"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
        <div className="flex items-center justify-center flex-col gap-5 h-full">
          <ul className="flex flex-col items-center justify-center space-y-10 ">
            <li>
              <a
                href="#"
                className="py-3 md:py-3.5 px-3.5 md:px-4 border border-black bg-[#FFE202] rounded shadow-[2px_2px_0px_0px_#000] uppercase text-[#1B1B1B] text-base font-monumentExtended font-bold tracking-[0.8px] transition-all duration-300 ease-in-out hover:shadow-[1px_1px_20px_3px_#000]"
              >
                About
              </a>
            </li>
            <li>
              <a
                href="#"
                className="py-3 md:py-3.5 px-3.5 md:px-4 border border-black rounded bg-white shadow-[2px_2px_0px_0px_#000] uppercase text-[#1B1B1B] text-base font-monumentExtended font-bold tracking-[0.8px] transition-all duration-300 ease-in-out hover:shadow-[1px_1px_20px_3px_#000]"
              >
                Tokenomics
              </a>
            </li>
          </ul>
          <div className="flex flex-col items-center gap-4 mt-4">
            <div className="flex items-center gap-3">
              <a
                href="#"
                target="_blank"
                className="flex flex-col justify-center items-center w-[56px] h-[56px] border border-black bg-[#FFE202] rounded shadow-[2px_2px_0px_0px_#000] uppercase text-[#1B1B1B] text-base font-monumentExtended font-bold tracking-[0.8px] transition-all duration-300 ease-in-out hover:shadow-[1px_1px_20px_3px_#000]"
              >
                <img src={telegram} alt="footer logo" />
              </a>
              <a
                href="#"
                target="_blank"
                className="flex flex-col justify-center items-center w-[56px] h-[56px] border border-black bg-white rounded shadow-[2px_2px_0px_0px_#000] uppercase text-[#1B1B1B] text-base font-monumentExtended font-bold tracking-[0.8px] transition-all duration-300 ease-in-out hover:shadow-[1px_1px_20px_3px_#000]"
              >
                <img src={twitter} alt="footer logo" />
              </a>
            </div>
          </div>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
