import React, { useRef } from "react";
import ChartImg from "../../assets/img/chart.png";
import { FileCopyIcon } from "../common/Icons";
function Tokenomics() {
  const inputRef = useRef(null);
  const copyToClipboard = () => {
    inputRef.current.select();
    document.execCommand("copy");
    alert("Copied to clipboard");
  };
  return (
    <>
      <div className="d-flex flex-col bg-[#8AE9FF]">
        <div className="w-full h-[255px] 2xl:h-[330px] cloud-bg"></div>
        <div className="max-w-[1724px] w-full mx-auto px-4 md:px-6">
          <div className="flex lg:flex-row flex-col justify-center gap-10 xl:gap-28 py-6">
            <div className="lg:max-w-[687px]">
              <div className="flex  flex-col">

                <div className="md:max-w-[996px] my-10 lg:my-4 xl:my-10 w-4/5 mx-auto lg:mx-0 lg:w-full rounded-full overflow-hidden ">
                  <img src={ChartImg} alt="chart" />
                </div>
                <p className="text-xl sm:text-[28px] xl:text-[40px] font-titanOne text-[#2C2525] text-center leading-[130%]">
                  <span className="font-bold text-[#000] ">
                    Look at there !!!
                  </span>
                </p>
              </div>
            </div>
            <div className=" lg:max-w-[854px]">
              <h2 className="uppercase text-[30px] sm:text-[50px] md:text-[70px] xl:text-[90px] font-titanOne font-normal headings leading-[100%] mt-3 sm:mt-5 text-start">
                TOKENOMICS
              </h2>
              <div className="flex flex-col gap-y-4 sm:gap-y-6 mt-8 md:mt-12 xl:mt-16">
                <div className="border border-[#060606] rounded-2xl bg-[#92E6FA] shadow-[0px_11px_4px_0px_rgba(0,0,0,0.25)] py-3 sm:py-6  xl:py-[30px] px-5 sm:px-8 xl:px-11">
                  <p className="text-[#060205] text-lg sm:text-2xl xl:text-4xl font-poppins">
                    Token Name: <span className="font-medium"> Groyd</span>
                  </p>
                </div>
                <div className="border border-[#060606] rounded-2xl bg-[#92E6FA] shadow-[0px_11px_4px_0px_rgba(0,0,0,0.25)] py-3 sm:py-6  xl:py-[30px] px-5 sm:px-8 xl:px-11 ">
                  <p className="text-[#060205] text-lg sm:text-2xl xl:text-4xl font-poppins">
                    Symbol:<span className="font-medium"> $GROYD</span>
                  </p>
                </div>
                <div className="border border-[#060606] rounded-2xl bg-[#92E6FA] shadow-[0px_11px_4px_0px_rgba(0,0,0,0.25)] py-3 sm:py-6  xl:py-[30px] px-5 sm:px-8 xl:px-11 ">
                  <p className="text-[#060205] text-lg sm:text-2xl xl:text-4xl font-poppins">
                    Total Supply:
                    <span className="font-medium"> 69,000,000,000</span>
                  </p>
                </div>
                <div className="border border-[#060606] rounded-2xl bg-[#92E6FA] shadow-[0px_11px_4px_0px_rgba(0,0,0,0.25)] py-3 sm:py-6  xl:py-[30px] px-5 sm:px-8 xl:px-11 ">
                  <p className="text-[#060205] text-lg sm:text-2xl xl:text-4xl font-poppins">
                    Decimal: <span className="font-medium"> 18</span>
                  </p>
                </div>
                <div className="border border-[#060606] rounded-2xl bg-[#92E6FA] shadow-[0px_11px_4px_0px_rgba(0,0,0,0.25)] py-3 sm:py-6  xl:py-[30px] px-5 sm:px-8 xl:px-11 flex justify-between">
                  <p className="text-[#060205] text-lg sm:text-2xl xl:text-4xl font-poppins w-[80%] whitespace-nowrap overflow-hidden flex items-center">
                    CA:
                    <input
                      ref={inputRef}
                      className="font-medium text-[#060205] text-lg sm:text-2xl xl:text-4xl font-poppins     bg-transparent outline-none w-full h-20px"
                      type="text"
                      value="0x674E685d81b796b19D3cAe777324125ea99052B2"
                      readOnly
                    />
                  </p>{" "}
                  <button onClick={copyToClipboard}>
                    <FileCopyIcon />
                  </button>
                </div>
                <div className="border border-[#060606] rounded-2xl bg-[#92E6FA] shadow-[0px_11px_4px_0px_rgba(0,0,0,0.25)] py-3 sm:py-6  xl:py-[30px] px-5 sm:px-8 xl:px-11 ">
                  <p className="text-[#060205] text-lg sm:text-2xl xl:text-4xl font-poppins">
                    Need 10MIL
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full h-[129px] layer-bg bg-[#67A11C] xl:mt-10 "></div>
      </div>
    </>
  );
}

export default Tokenomics;
