import React from "react";
import heroimg from "../../assets/img/hero-img.png";
import twitter1 from "../../assets/img/twitter1.png";
import telgram1 from "../../assets/img/teligram1.png";

function Footer() {
  return (
    <>
      <div className="w-full 2xl:h-[300px] h-[272px] bg-footer-top"></div>
      <div className="footer-bg flex flex-col pb-20 -mt-1 md:pt-10 px-4">
        <div className="flex items-center justify-center gap-x-8">
          <div className="w-[122px] h-[122px] rounded-full border border-white overflow-hidden">
            <img src={heroimg} alt="img" />
          </div>
          <h2 className="uppercase text-[30px] sm:text-[50px] md:text-[70px] lg:text-[90px] font-titanOne font-normal headings leading-[100%] mt-3 sm:mt-5">
            groyd
          </h2>
        </div>
        <p className="my-6 md:my-10 text-lg sm:text-2xl lg:text-[30px] font-poppins leading-[150%] text-black max-w-[845px] text-center mx-auto">
          Shoot and Growth with GROYD. Love it, build it.
        </p>
        <div className="flex items-center gap-x-4 sm:gap-x-6 justify-center mb-8">
          <a
            href="https://t.me/groydportal"
            target="_blank"
            className="flex flex-col justify-center items-center w-[56px] sm:w-[85px] h-[56px] sm:h-[85px] border border-black bg-[#FFE202] rounded shadow-[2px_2px_0px_0px_#000] uppercase text-[#1B1B1B] text-base font-monumentExtended font-bold tracking-[0.8px] transition-all duration-300 ease-in-out hover:shadow-[1px_1px_20px_3px_#000]"
          >
            <img src={telgram1} alt="footer logo" />
          </a>
          <a
            href="https://x.com/GroydEth"
            target="_blank"
            className="flex flex-col justify-center items-center w-[56px] sm:w-[85px] h-[56px] sm:h-[85px] border border-black bg-[#FFE202] rounded shadow-[2px_2px_0px_0px_#000] uppercase text-[#1B1B1B] text-base font-monumentExtended font-bold tracking-[0.8px] transition-all duration-300 ease-in-out hover:shadow-[1px_1px_20px_3px_#000]"
          >
            <img src={twitter1} alt="footer logo" />
          </a>
        </div>
      </div>
    </>
  );
}

export default Footer;
