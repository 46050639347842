import React from "react";
import { StarIcon } from "./Icons";
import { groydMarquee } from "./Helper";

const CommonSlider = ({ dir }) => {
  return (
    <div
      className={
        "py-2 lg:py-[18px] overflow-x-hidden w-full mx-auto bg-[#8CC741] border-2 border-[#486620] "
      }
    >
      <marquee scrollamount="15" behavior="scroll" direction={dir} loop="">
        <div className="flex gap-4 sm:gap-8 items-center ">
          {groydMarquee.map((item, index) => (
            <div key={index} className="flex items-center gap-4 sm:gap-8">
              <h2 className="font-normal font-titanOne  leading-normal uppercase text-lg sm:text-[22px] text-slide">
                {item.title}
              </h2>
              <StarIcon />
            </div>
          ))}
        </div>
      </marquee>
    </div>
  );
};

export default CommonSlider;
