/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import logoImage from "../../assets/img/logo.png";
import telegram from "../../assets/img/telegram.png";
import twitter from "../../assets/img/twitter.png";
import Sidebar from "./Sidebar"; // Import Sidebar component

const Navbar = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false); // State to manage sidebar visibility
  useEffect(() => {
    if (isSidebarOpen) {
      document.body.classList.add("sidebar-open");
    } else {
      document.body.classList.remove("sidebar-open");
    }
    return () => {
      document.body.classList.remove("sidebar-open");
    };
  }, [isSidebarOpen]);

  return (
    <>
      <div className="md:mt-4 max-w-[1724px] w-full mx-auto  md:px-6 relative z-20">
        <div className="bg-[#93D142] px-3 lg:px-4 py-3 lg:py-4  flex gap-2 justify-between rounded border border-[#4D662C] shadow-[0px_8px_2px_0px_#0E1938] md:shadow-[8px_8px_0px_0px_#0E1938]">
          <ul className="hidden md:flex items-center gap-4 lg:gap-6 flex-row">
            <li>
              <a
                href="#"
                className="py-3 md:py-3.5 px-3.5 md:px-4 border border-black bg-[#FFE202] rounded shadow-[2px_2px_0px_0px_#000] uppercase text-[#1B1B1B] text-base font-monumentExtended font-bold tracking-[0.8px] transition-all duration-300 ease-in-out hover:shadow-[1px_1px_20px_3px_#000]"
              >
                About
              </a>
            </li>
            <li>
              <a
                href="#"
                className="py-3 md:py-3.5 px-3.5 md:px-4 border border-black rounded bg-white shadow-[2px_2px_0px_0px_#000] uppercase text-[#1B1B1B] text-base font-monumentExtended font-bold tracking-[0.8px] transition-all duration-300 ease-in-out hover:shadow-[1px_1px_20px_3px_#000]"
              >
                Tokenomics
              </a>
            </li>
          </ul>
          <div>
            <a href="#" className="flex gap-2 lg:gap-3 items-center">
              <img src={logoImage} alt="logo" width={59} height={59} />
              <p className="font-titanOne font-normal nav-logo text-[28px] md:text-[34px] uppercase">
                groyd to grow
              </p>
            </a>
          </div>
          <div className="flex gap-4 items-center">
            <div className="md:flex items-center gap-4 lg:gap-6 hidden">
              <a
                href="https://t.me/groydportal"
                target="_blank"
                className="flex flex-col justify-center items-center w-[56px] h-[56px] border border-black bg-[#FFE202] rounded shadow-[2px_2px_0px_0px_#000] uppercase text-[#1B1B1B] text-base font-monumentExtended font-bold tracking-[0.8px] transition-all duration-300 ease-in-out hover:shadow-[1px_1px_20px_3px_#000]"
              >
                <img src={telegram} alt="footer logo" />
              </a>
              <a
                href="https://x.com/GroydEth"
                target="_blank"
                className="flex flex-col justify-center items-center w-[56px] h-[56px] border border-black bg-white rounded shadow-[2px_2px_0px_0px_#000] uppercase text-[#1B1B1B] text-base font-monumentExtended font-bold tracking-[0.8px] transition-all duration-300 ease-in-out hover:shadow-[1px_1px_20px_3px_#000]"
              >
                <img src={twitter} alt="footer logo" />
              </a>
            </div>

            <button
              className="md:hidden text-white"
              onClick={() => setSidebarOpen(true)} // Toggle sidebar visibility
            >
              <svg
                width="36"
                height="36"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 6H20M4 12H20M4 18H20"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <Sidebar isSidebarOpen={isSidebarOpen} setSidebarOpen={setSidebarOpen} />
    </>
  );
};

export default Navbar;
