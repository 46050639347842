export const groydMarquee = [
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
  {
    title: "GROYD",
  },
];
