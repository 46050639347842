import React from "react";
import HeroImg from "../../assets/img/hero-img.png";
import CommonSlider from "../common/CommonSlider";
import Coin1 from "../../assets/img/coin-1.png";
import Coin2 from "../../assets/img/coin-2.png";
import Coin3 from "../../assets/img/coin-3.png";
import Coin4 from "../../assets/img/coin-4.png";

function Hero() {
  return (
    <>
      <div className="relative overflow-hidden">
        <div className="absolute top-24 md:top-36 left-[40%] max-w-[50px] sm:max-w-[70px] lg:max-w-[100px] xl:max-w-[140px]">
          <img src={Coin1} alt="coin" className="w-full" />
        </div>
        <div className="absolute top-80 md:top-[500px] left-[5%] sm:left-[10%] max-w-[50px] sm:max-w-[70px] lg:max-w-[100px] xl:max-w-[140px]">
          <img src={Coin2} alt="coin" className="w-full" />
        </div>
        <div className="absolute top-80  md:top-[550px] right-[5%] sm:right-[10%] max-w-[50px] sm:max-w-[70px] lg:max-w-[100px] xl:max-w-[140px]">
          <img src={Coin3} alt="coin" className="w-full" />
        </div>
        <div className="absolute top-[450px] md:top-[570px] lg:top-[680px] left-[5%] sm:left-[18%] md:left-[25%] max-w-[50px] sm:max-w-[70px] lg:max-w-[100px] xl:max-w-[140px]">
          <img src={Coin4} alt="coin" className="w-full" />
        </div>
        <div className="hero-bg min-h-[60vh]  md:min-h-[80vh] py-28 md:py-40 flex flex-col justify-center items-center">
          <h1 className="text-[80px] sm:text-[100px] md:text-[140px] lg:text-[200px] xl:text-[248px] font-titanOne main-heading leading-[100%]">
            GROYD
          </h1>
          <div className="max-w-[250px] sm:max-w-[320px] lg:max-w-[408px] mx-auto">
            <img src={HeroImg} alt="groyd" />
          </div>
        </div>
        <div className="absolute bottom-16 md:bottom-20 rotate-6 md:rotate-3 left-0 w-full">
          <CommonSlider dir="left" />
        </div>
        <div className="absolute bottom-16 md:bottom-24 -rotate-6 md:-rotate-3 left-0 w-full">
          <CommonSlider dir="right" />
        </div>
      </div>
    </>
  );
}

export default Hero;
