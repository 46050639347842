import React from "react";
import telegram from "../../assets/img/telegram.png";
import twitter from "../../assets/img/twitter.png";
import HeroImg from "../../assets/img/community.png";
import HeroImg2 from "../../assets/img/community2.png";
import mindimg from "../../assets/img/mind.png";
function OurCommunity() {
  return (
    <>
      <div className="xl:pt-36 pt-20 pb-20 xl:pb-52 w-full flex flex-col px-4 sm:px-5">
        <div className="max-w-[300px] md:max-w-[596px] mx-auto -mb-12 md:-mb-20 relative z-30">
          <img src={HeroImg2} alt="img" className="w-full" />
        </div>

        <div className="relative  max-w-[1391px] w-full  mx-auto">
          <div className="absolute -left-40 -top-40  z-0 max-w-[300px] xl:max-w-[350px] ">
            <img src={mindimg} alt="" className="w-full" />
          </div>
          <div className=" relative z-20 rounded-2xl shadow-[3px_3px_0px_2px_rgba(0,0,0,0.75)] flex flex-col justify-center items-center gap-6 lg:gap-9 py-16 lg:py-[100px] px-5 sm:px-9 lg:px-14  bg-[#A0DF4F] ">
            <h2 className="uppercase text-[30px] sm:text-[50px] md:text-[60px] xl:text-[90px] font-titanOne  -normal headings leading-[100%] mt-3 sm:mt-5">
              Groyd to GROWTH!
            </h2>
            <p className="text-black text-lg sm:text-2xl lg:text-[32px] leading-[150%] font-normal font-poppins text-center  max-w-[1200px] mx-auto">
              {" "}
              Groyd is an offshoot of Pepe the Frog based on George Floyd. Various illustrations have been made of Groyd, often with a parasitic cyclops frog, known as Ni, mind controlling him.


            </p>

            <ul className="lg:mt-16 mt-10 flex  flex-row items-center gap-3 sm:gap-4 md:gap-9">
              <li>
                <a
                  href="https://app.uniswap.org/swap?outputCurrency=0x674E685d81b796b19D3cAe777324125ea99052B2&chain=ethereum"
                  className="py-2.5 sm:py-3 md:py-8 px-5 sm:px-6 md:px-16 border-[1.4px] border-black rounded-2xl font-luckiestGuy bg-white shadow-[0px_5px_0px_0px_#000] uppercase text-black text-lg sm:text-2xl transition-all duration-300 ease-in-out hover:shadow-[1px_1px_20px_3px_#000] "
                >
                  BUY NOW
                </a>
              </li>
              <li>
                <a
                  href="https://t.me/groydportal"
                  target="_blank"
                  className="flex flex-col justify-center items-center w-[40px] h-[40px] sm:w-[56px] sm:h-[56px] md:w-[95px] md:h-[95px] border border-black 
                bg-[#8AE9FF] rounded-xl  shadow-[5px_5px_0px_0px_#000] uppercase text-[#1B1B1B] text-base font-monumentExtended font-bold tracking-[0.8px] transition-all duration-300 ease-in-out hover:shadow-[1px_1px_20px_3px_#000]"
                >
                  <img
                    src={telegram}
                    alt="footer logo"
                    className="md:w-[50px]"
                  />
                </a>
              </li>{" "}
              <li>
                <a
                  href="https://x.com/GroydEth"
                  target="_blank"
                  className="flex flex-col justify-center items-center w-[40px] h-[40px] sm:w-[56px] sm:h-[56px] md:w-[95px] md:h-[95px] border border-black 
                bg-[#8AE9FF] rounded-xl  shadow-[5px_5px_0px_0px_#000] uppercase text-[#1B1B1B] text-base font-monumentExtended font-bold tracking-[0.8px] transition-all duration-300 ease-in-out hover:shadow-[1px_1px_20px_3px_#000]"
                >
                  <img
                    src={twitter}
                    alt="footer logo"
                    className="md:w-[50px]"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default OurCommunity;
